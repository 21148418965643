<template>
  <loading v-if="loading" />

  <no-results
    v-else-if="!orderedSecrets.length"
    :cta="{ label: 'Add secret' }"
    icon="fingerprint"
    title="No secrets"
    message="No secrets added to this site yet."
    @click="addSecret"
  />

  <div v-else>
    <div class="message is-dark has-margin-bottom-100">
      <div class="message-body has-padding-75 has-text-dark">
        <apart>
          <p>
            Use site secrets to share <strong>sensitive</strong> information.
          </p>
          <button
            class="button is-dark is-rounded is-outlined"
            @click="addSecret"
          >
            Add secret
          </button>
        </apart>
      </div>
    </div>

    <secret
      v-for="secret in orderedSecrets"
      :key="secret._id"
      :secret="secret"
      @decode="decodeSecret(secret)"
      @edit="editSecret(secret)"
      @remove="removeSecret(secret)"
    />
  </div>
</template>

<script>
export default {
  name: "SiteSecrets",
  components: {
    secret: () => import("@shared/vault/_secretField")
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      processing: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    isDelegate() {
      return (
        this.$store.getters["sites/site/userRole"](this.siteId) === "delegate"
      );
    },
    secrets() {
      return this.$_(
        this.$store.getters["sites/siteSecrets"]({
          siteId: this.siteId
        })
      )
        .filter(s => !this.isDelegate || !s.isHiddenFromDelegates)
        .value();
    },
    orderedSecrets() {
      return this.$_.orderBy(this.secrets, ["label"], ["asc"]);
    },
    totalSecrets() {
      return this.$_.size(this.secrets);
    }
  },
  created() {
    this.$store
      .dispatch("sites/observeSecrets", { siteId: this.siteId })
      .then(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSecrets", { siteId: this.siteId });
  },
  methods: {
    decodeSecret(secret) {
      this.$set(secret, "state", "processing");
      this.$store
        .dispatch("sites/decodeValue", {
          siteId: secret.siteId,
          value: secret.value,
          secretId: secret._id
        })
        .then(result => {
          this.$set(secret, "value", result.value);
          this.$set(secret, "state", "decoded");
        })
        .catch(error => {
          this.$set(secret, "state", "error");
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    },
    addSecret() {
      this.$modal.open({
        component: () => import("@shared/sites/_siteSecretModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId
        }
      });
    },
    editSecret(secret) {
      this.$modal.open({
        component: () => import("@shared/sites/_siteSecretModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId,
          secret: secret
        }
      });
    },
    removeSecret({ siteId, _id }) {
      this.processing = true;
      this.$store
        .dispatch("sites/removeSecret", {
          siteId: siteId,
          secretId: _id
        })
        .then(result => {
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            type: "is-success",
            queue: false
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
